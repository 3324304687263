.PersonSimple {
  text-align: left;
  width: 100%;
}
.sms {
  display: inline-block;
  float: right;
  margin-right: 10px;
}
.icon {
  height: 16px;
  width: 16px;
}
.name {
  display: inline-block;
  margin-right: 20px;
  float: left;
}
.address {
  display: inline-block;
  font-size: calc(5px + 2vmin);
}