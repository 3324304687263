.App {
  text-align: center;
  background-color: #282c34;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(9px + 2vmin);
  color: white;
}
.main {
  width: 96%;
}
#div_top_hypers {
  background-color:#eeeeee;
  display:inline; 
  margin-left: -40px;
}
#ul_top_hypers ul{
}
.active a {
  text-decoration: underline;
}
a {
  color:black;
  text-decoration: none;
}
#ul_top_hypers li {
    display: inline-block;
    width: 17%;
    color: black;
    background-color: lightblue;
    border: solid 2px;
}
#ul_top_hypers li.active {
  color:yellow;
  background-color: white;
}
.party {
  display: inline-block;
  float: right;
  margin-left: 5px;
}
.Democrat {
  background-color: blue;
  color: white;
}
.Republican {
  background-color: red;
  color: white;
}
.Unaffiliated {
  background-color: white;
  color: black;
}
.oddrow {
  background-color: dimgrey;
}
.evenrow {
  background-color: gray;

}
.infocus {
  background-color:turquoise;
  color: black;
  border: solid 1px;
}
.tags {
  list-style: none;
  display: inline-block;
}
.tags li {
  
}
.tags li::before {
  content: "📌";
}

.faded {
    opacity: 0.3;
    font-size: 50%;
}

.freeform {
  width: 80%;
}
