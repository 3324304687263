.VotePledge {
}

#vpledge ul {
  width: 50%;
}

#vpledge li {
    display: block;
    padding: 5px;
    color: darkblue;
    background-color: white;
    border: solid 2px burlywood;
    width: 19%;
}