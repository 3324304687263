.Dashboard {
}
.tagChart {
  display: inline-block;
  width: 45%;
}
.tagLabel {
  display: inline-flex;
}
.tagCount {
  display: inline-flex;
  float: right;
}