.VoterMap {
}

.map_overlay {
  background-color: rgb(52, 52, 58);
  color: white;
  padding: 5px 5px;
  display: inline-block;
  border-radius: 10%;
  transform: translate(-50%, -50%);
}

.moreinfo {
  color: aqua;
  width: max-content;
}

.mini {
  font-size: x-small;
  display: inline-flex;
}
.vperson {

}